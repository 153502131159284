import React, { FC, useEffect, useRef } from "react";
import { VideoPlayerEvent } from "../types";
import { Observable } from "rxjs";

type Props = {
  incomingCommand$: Observable<VideoPlayerEvent>;
};

const ControllableVideo: FC<Props> = ({ incomingCommand$ }) => {
  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  useEffect(() => {
    const subscription = incomingCommand$.subscribe((command) => {
      switch (command.type) {
        case "playFromStart":
          videoRef.current.currentTime = 0;
          videoRef.current.play();
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          break;
        case "pause":
          videoRef.current.pause();
          audioRef.current.pause();
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [incomingCommand$]);

  return (
    <div>
      <h1 style={{ margin: "16px" }}>Auto Controlled Video</h1>
      <video ref={videoRef} style={{ height: "50%", width: "50%", margin:"16px" }} loop playsInline>
        <source src="assets/LabsWeekDemoClean.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <audio ref={audioRef} loop>
        <source src={"assets/LabsWeekADClip.mp4"} type="audio/mp4" />
      </audio>
    </div>
  );
};

export default ControllableVideo;
