import { Subject } from "rxjs";
import { VideoPlayerEvent } from "../types";
import React, { FC } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import VideoPlayer from "./VideoPlayer";

type Props = {
  videoEvents$: Subject<VideoPlayerEvent>;
};

const VideoPlayerPage: FC<Props> = ({ videoEvents$ }) => {
  return (
    <ChakraProvider>
      <div style={{ width: "100%", height: "100%", backgroundColor: "grey" }}>
        <VideoPlayer events$={videoEvents$} />
      </div>
    </ChakraProvider>
  );
};

export default VideoPlayerPage;
