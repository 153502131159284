import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { ConnectionEvent, VideoPlayerEvent } from "../types";
import { Observable, Subject } from "rxjs";
import ControllableVideo from "./ControllableVideo";

type Props = {
  connectionEvents$: Subject<ConnectionEvent>;
  incomingEvent$: Observable<VideoPlayerEvent>;
};

const pattern = /^[A-Z]{3}$/;

const AudioPage: FC<Props> = ({ connectionEvents$, incomingEvent$ }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("form");

  useEffect(() => {
    const subscription = incomingEvent$.subscribe((vpEvent) => {
      console.log("vpEvent", vpEvent);
      switch (vpEvent.type) {
        case "connected":
          setMode("playing");
          break;
        case "playFromStart":
          console.log("play from start");
          break;
        case "pause":
          console.log("pause");
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [incomingEvent$]);

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    const newValue = e.target.value.toUpperCase();
    setInputValue(newValue);
    const result = pattern.test(newValue);
    setIsSubmitEnabled(result);
  }

  function handleSubmit(e: FormEvent): void {
    e.preventDefault();
    if (pattern.test(inputValue)) {
      connectionEvents$.next({
        type: "connectTo",
        id: inputValue,
      });
      setIsLoading(true);
    }
  }

  const theForm = (
    <Box maxWidth={"400px"} padding={"16px"}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Connect to id:</FormLabel>
          <Input
            type="text"
            placeholder={"ABC"}
            onChange={handleChange}
            value={inputValue}
          />
        </FormControl>
        <Button
          mt={4}
          colorScheme="teal"
          type="submit"
          isDisabled={!isSubmitEnabled}
          isLoading={isLoading}
        >
          Connect
        </Button>
      </form>
    </Box>
  );

  const thePlayer = <ControllableVideo incomingCommand$={incomingEvent$} />;

  return <div>{mode === "form" ? theForm : thePlayer}</div>;
};
export default AudioPage;
