import Peer from "peerjs";
import { VideoPlayerEvent } from "../types";
import { Observable, Subject } from "rxjs";

const API_KEY = "squidz";
const idPrefix = "multiVideoStream2021Nov14";

const videoEventPattern = /^playFromStart$|^pause$|^connected$|^disconnected$/;

class PeerService {
  private peer: Peer | null = null;
  private connectionsToVideoPlayer: Peer.DataConnection[] = [];
  private audioConnections: Peer.DataConnection[] = [];
  private readonly id: string;
  private incomingVideoPlayerEvents: Subject<VideoPlayerEvent> = new Subject();

  constructor(id: string) {
    console.log("PEER CONSTRUCTOR",id)
    this.id = `${idPrefix}${id}`;
  }

  public init() {
    this.peer = new Peer(this.id, {
      host: "peer.skyixd.com",
      secure: true,
      key: API_KEY,
      // debug: 3,
    });

    this.peer.on("open", (id) => {
      console.log("My peer ID is: " + id);
    });

    this.peer.on("connection", this.handleConnection.bind(this));
  }

  // Connection From Audio Player
  private handleConnection(conn: Peer.DataConnection) {
    console.log("Incoming connection", conn);
    this.audioConnections.push(conn);

    this.incomingVideoPlayerEvents.next({
      type: "connected",
    });

    // Connection from Audio Player
    conn.on("data", (data) => {
      console.log("Incoming data", data);
    });
  }

  // Connect to Video Player
  public connect(shortId: string) {

    console.log("COnnecting to video player",shortId)

    const fullId = `${idPrefix}${shortId}`;
    if (this.peer) {

      console.log("Really connecting")
      const conn = this.peer.connect(fullId);
      conn.on("open", () => {
        this.incomingVideoPlayerEvents.next({ type: "connected" });
        conn.send({ type: "message", payload: "Hello" });
      });
      conn.on("data", (data) => {
        console.log("Incoming data",data.type)
        if (data && data.type && videoEventPattern.test(data.type)) {
          this.incomingVideoPlayerEvents.next({ type: data.type });
        }
      });
      this.connectionsToVideoPlayer.push(conn);
    } else {
      console.log("Peer is not initialized");
    }
  }

  public get incomingVideoPlayerEvents$(): Observable<VideoPlayerEvent> {
    return this.incomingVideoPlayerEvents as Observable<VideoPlayerEvent>;
  }

  public send(videoEvent: VideoPlayerEvent) {
    console.log(
      "Outbound connection count",
      this.audioConnections.length
    );
    this.audioConnections.forEach((c) => {
      console.log("SENDING", videoEvent);
      c.send(videoEvent);
    });
  }
}

export default PeerService;
