import styled from "@emotion/styled";
import React, { FC, useRef, useState } from "react";
import { Subject } from "rxjs";
import { VideoPlayerEvent } from "../types";
import { Box } from "@chakra-ui/react";

const StyledCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const buttonColor = `#EEEEEE`;
const buttonRolloverColor = `#FFFFFF`;

const VideoButton = styled.button`
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;

  border-color: transparent transparent transparent ${buttonColor};
  transition: 100ms all ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 37px 0 37px 60px;

  &.paused {
    border-style: double;
    border-width: 0px 0 0px 60px;
  }

  &:hover {
    border-color: transparent transparent transparent ${buttonRolloverColor};
  }
`;

type Props = {
  events$: Subject<VideoPlayerEvent>;
};

const VideoPlayer: FC<Props> = ({ events$ }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isOverVideo, setIsOverVideo] = useState<boolean>(true);
  const videoRef = useRef<any>();

  function handleClick() {
    const element = videoRef.current;

    setIsPlaying((playing) => {
      if (!playing) {
        if (element) {
          const vid: HTMLVideoElement = element as HTMLVideoElement;
          vid.currentTime = 0;
          events$.next({ type: "playFromStart" });
          vid.play();
        }
      } else {
        if (element) {
          const vid: HTMLVideoElement = element as HTMLVideoElement;
          events$.next({ type: "pause" });
          vid.pause();
        }
      }

      return !playing;
    });
  }

  function handleMouseEnter() {
    setIsOverVideo(true);
  }

  function handleMouseLeave() {
    setIsOverVideo(false);
  }

  return (
    <Container>
      <video
        ref={videoRef}
        style={{ height: "100%", width: "100%" }}
        loop
        playsInline
      >
        <source src="assets/LabsWeekDemoClean_1_aac2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <StyledCover
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          opacity={isOverVideo ? 1 : 0}
          transition={"opacity 0.4s ease-in-out"}
        >
          <VideoButton className={isPlaying ? "paused" : ""} />
        </Box>
      </StyledCover>
    </Container>
  );
};

export default VideoPlayer;
