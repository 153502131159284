import React, { useMemo } from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VideoPlayerPage from "./components/VideoPlayerPage";
import AudioPage from "./components/AudioPage";
import Navbar from "./components/Navbar";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Store from "./services/Store";

const store = new Store();

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "gray.400",
        color: "black",
      },
    },
  },
});

function App() {

  const id: string = useMemo(() => store.id, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar id={id} />
        <Routes>
          <Route
            path="/"
            element={<VideoPlayerPage videoEvents$={store.videoEvents$} />}
          />
          <Route
            path="/audio"
            element={
              <AudioPage
                connectionEvents$={store.connectionEvent$}
                incomingEvent$={store.incomingVideoEvents$}
              />
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
