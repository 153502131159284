import { ConnectionEvent, VideoPlayerEvent } from "../types";
import { Observable, Subject } from "rxjs";
import PeerService from "./PeerService";
import { generateRandomLetters } from "../utils/utils";

class Store {
  private _videoEvents$: Subject<VideoPlayerEvent> =
    new Subject<VideoPlayerEvent>();
  private _connectionEvents$: Subject<ConnectionEvent> =
    new Subject<ConnectionEvent>();
  private _incomingVideoEvents$: Observable<VideoPlayerEvent>;

  private _id: string = generateRandomLetters(3);

  private static _instance: Store | null = null;

  public static getInstance(): Store {
    if (!Store._instance) {
      Store._instance = new Store();
    }
    return Store._instance;
  }

  public constructor() {
    console.log("Constructor");

    const peerService = new PeerService(this._id);
    peerService.init();

    this._connectionEvents$.subscribe((connectionEvent) => {
      console.log("local event", connectionEvent);
      if (connectionEvent.type === "connectTo") {
        peerService.connect(connectionEvent.id);
      }
    });

    this._videoEvents$.subscribe((videoEvent) => {
      // console.log(videoEvent);
      peerService.send(videoEvent);
    });

    this._incomingVideoEvents$ = peerService.incomingVideoPlayerEvents$;
  }

  public get videoEvents$(): Subject<VideoPlayerEvent> {
    return this._videoEvents$;
  }
  public get connectionEvent$(): Subject<ConnectionEvent> {
    return this._connectionEvents$;
  }
  public get incomingVideoEvents$(): Observable<VideoPlayerEvent> {
    return this._incomingVideoEvents$;
  }

  public get id(): string {
    return this._id;
  }
}

export default Store;
